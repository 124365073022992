import { RouterNavigateOptions } from '@remix-run/router'
import { PluginRegistry, Plugin, PluginInfo } from 'plugin-system'
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'

type ShellNavigateCommand = {
  id: 'shell.navigate'
  to: string
  from?: string
  options?: RouterNavigateOptions
}

type CreateRouter = typeof createBrowserRouter
type CreateRouterWrapper = (arg: CreateRouter) => CreateRouter

export default class RouterPlugin extends Plugin {
  get info(): PluginInfo {
    return { name: 'router' }
  }

  async activate(registry: PluginRegistry): Promise<void> {
    const routes = registry.slots.getOrderedValues<RouteObject>('shell.routes')
    if (!routes.length) {
      console.warn(
        'No routes provided. You probably need to fill some routes into the `shell.routes` slot.',
      )
    }

    const createRouter: CreateRouter = registry.slots
      .getOrderedValues<CreateRouterWrapper>('shell.router.wrapCreateBrowserRouter')
      .reduceRight((acc, wrapper) => wrapper(acc), createBrowserRouter)

    const router = createRouter(routes)

    registry.fillSlot('shell.content', () => <RouterProvider router={router} />, {
      key: 'router',
      ifKeyExists: 'replace',
    })

    registry.registerCommand<ShellNavigateCommand>({
      id: 'shell.navigate',
      handler: ({ to, from, options }) => {
        if (from && router.state.location.pathname !== from) return
        router.navigate(to, options)
      },
    })
  }
}
