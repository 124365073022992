var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/plugins/profile-setup/Plugin.tsx
import { Plugin } from "plugin-system";

// src/components/profile-setup/CreatorProfileSetupDialog.tsx
import { useApi as useApi2, useEditOrgProfile, useOrgProfile, useUpdatePayoutAddress } from "common-api";
import { NotificationEvent, WizardSingleDialog, useMobileEnabled } from "common-ui";
import { useCallback, useEffect as useEffect2, useMemo as useMemo2 } from "react";
import { useSnapshot as useSnapshot2 } from "valtio";
import { useAuth as useAuth2 } from "common-ui";
import { usePluginRegistry } from "plugin-system";
import { Stack as Stack4, Typography as Typography5 } from "@mui/material";

// src/state/profile-setup/index.ts
import {
  WizardDialogManager
} from "common-ui";
import { proxy as proxy5 } from "valtio";

// ../../common/api/src/sdks/sdk-platform-beta.ts
import globalAxios2 from "axios";

// ../../common/api/src/sdks/base.ts
import globalAxios from "axios";

// ../../common/api/src/sdks/sdk-platform-beta.ts
var EditOrganizationProfileInputCreatorRolesEnum = {
  Artist: "ARTIST",
  ContentCreator: "CONTENT_CREATOR",
  EventOrganizer: "EVENT_ORGANIZER",
  CommunityManager: "COMMUNITY_MANAGER",
  NoneApply: "NONE_APPLY"
};
var OrganizationProfileOutputCreatorRolesEnum = {
  Artist: "ARTIST",
  ContentCreator: "CONTENT_CREATOR",
  EventOrganizer: "EVENT_ORGANIZER",
  CommunityManager: "COMMUNITY_MANAGER",
  NoneApply: "NONE_APPLY"
};

// src/state/profile-setup/username.ts
import { proxy } from "valtio";
import * as z from "zod";
var validationSchema = z.object({
  userSlug: z.string().min(5, { message: "Username must contain at least 5 characters" }).max(75).refine((value) => /^[a-z0-9](?:[a-z0-9_]*[a-z0-9])?$/.test(value), {
    message: "Please enter a valid username (lowercase letters, numbers, and underscores allowed)"
  }),
  creatorRoles: z.array(z.string()).optional()
});
var defaultStateValues = {
  userSlug: "",
  creatorRoles: []
};
var reset = () => {
  for (const key in defaultState) {
    ;
    state[key] = defaultState[key];
  }
};
var defaultState = {
  stepType: "CREATOR_USERNAME",
  userSlug: "",
  title: "",
  isStatic: true,
  customButtons: null,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "BACKEND",
  defaults: defaultStateValues,
  reset
};
var state = proxy(__spreadValues({}, defaultState));
var commit = (editOrgProfile, candidateState) => __async(void 0, null, function* () {
  var _a;
  return yield editOrgProfile.mutateAsync({
    slug: candidateState.userSlug,
    creator_roles: !((_a = candidateState.creatorRoles) == null ? void 0 : _a.length) ? [EditOrganizationProfileInputCreatorRolesEnum.NoneApply] : candidateState.creatorRoles
  }).then((res) => res == null ? void 0 : res.data);
});

// src/state/profile-setup/welcome-page.ts
import { proxy as proxy2 } from "valtio";
import * as z2 from "zod";
var validationSchema2 = z2.object({});
var defaultStateValues2 = {};
var reset2 = () => {
  for (const key in defaultState2) {
    ;
    state2[key] = defaultState2[key];
  }
};
var defaultState2 = {
  stepType: "WELCOME_PAGE",
  title: "",
  isStatic: true,
  customButtons: null,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema: validationSchema2,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "MEMORY",
  defaults: defaultStateValues2,
  reset: reset2
};
var state2 = proxy2(__spreadValues({}, defaultState2));

// src/state/profile-setup/optional.ts
import { isAddress } from "ethers";
import { proxy as proxy3 } from "valtio";
import * as z3 from "zod";
var validationSchema3 = z3.object({
  bannerUrl: z3.string().min(1).optional(),
  avatarUrl: z3.string().min(1).optional(),
  displayName: z3.string().min(1).max(80).optional(),
  creatorBio: z3.string().min(1).max(64).optional(),
  websiteLink: z3.string().url().optional(),
  instagramLink: z3.string().min(1).refine((value) => /^.*instagram\.com.*$/.test(value), {
    message: "Please enter a valid instagram link."
  }).optional(),
  twitterLink: z3.string().min(1).refine((value) => /^.*twitter\.com.*$/.test(value), {
    message: "Please enter a valid twitter link."
  }).optional(),
  discordLink: z3.string().min(1).refine((value) => /^.*discord\..*$/.test(value), {
    message: "Please enter a valid discord link."
  }).optional(),
  payoutAddress: z3.string().max(42).nullish().refine((val) => !val || isAddress(val), {
    message: "Please enter a valid payout address"
  }),
  organizationId: z3.string().nullish()
});
var defaultStateValues3 = {
  bannerUrl: "",
  avatarUrl: "",
  displayName: "",
  creatorBio: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: "",
  payoutAddress: ""
};
var reset3 = () => {
  for (const key in defaultState3) {
    ;
    state3[key] = defaultState3[key];
  }
};
var defaultState3 = {
  stepType: "OPTIONAL_INFO",
  displayName: "",
  creatorBio: "",
  title: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: "",
  customButtons: null,
  isStatic: true,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema: validationSchema3,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "BACKEND",
  defaults: defaultStateValues3,
  reset: reset3
};
var state3 = proxy3(__spreadValues({}, defaultState3));
var commit2 = (editOrgProfile, updatePayoutAddress, candidateState) => __async(void 0, null, function* () {
  if (candidateState.payoutAddress && candidateState.organizationId) {
    yield updatePayoutAddress({
      organizationId: candidateState.organizationId,
      payoutAddress: candidateState.payoutAddress
    });
  }
  return yield editOrgProfile.mutateAsync({
    media: {
      banner: candidateState.bannerUrl,
      avatar: candidateState.avatarUrl
    },
    socials: {
      instagram: candidateState.instagramLink,
      twitter: candidateState.twitterLink,
      discord: candidateState.discordLink
    },
    public_name: candidateState.displayName,
    description: candidateState.creatorBio
  }).then((res) => res == null ? void 0 : res.data);
});

// src/state/profile-setup/complete.ts
import { proxy as proxy4 } from "valtio";
import * as z4 from "zod";
var validationSchema4 = z4.object({});
var defaultStateValues4 = {};
var reset4 = () => {
  for (const key in defaultState4) {
    ;
    state4[key] = defaultState4[key];
  }
};
var defaultState4 = {
  stepType: "COMPLETE",
  title: "",
  isStatic: false,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema: validationSchema4,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "MEMORY",
  defaults: defaultStateValues4,
  reset: reset4
};
var state4 = proxy4(__spreadValues({}, defaultState4));

// src/state/profile-setup/index.ts
var stepTypeStateMap = {
  WELCOME_PAGE: state2,
  CREATOR_USERNAME: state,
  OPTIONAL_INFO: state3,
  COMPLETE: state4
};
var rootStateDefaults = {
  open: true,
  //set this based on the user's profile setup status, specifically their slug
  isStatic: true,
  bootstrapContext: void 0,
  previousStepType: void 0,
  nextStepType: "CREATOR_USERNAME",
  customButtons: null,
  activeStep: state2
};
var defaultStateValues5 = __spreadValues({
  defaults: rootStateDefaults
}, rootStateDefaults);
var state5 = proxy5(__spreadValues({}, defaultStateValues5));
var ProfileSetupWizardDialogManager = class extends WizardDialogManager {
  bootstrap() {
    console.error("not implemented for Phosphor profile setup");
  }
  memoryActiveStepCommit(activeStepCandidateValues) {
    this.wizardState.activeStep.committed = activeStepCandidateValues ? __spreadValues({}, activeStepCandidateValues) : {};
    this.wizardState.activeStep.status = "COMMITTED";
  }
  commitActiveStepState(activeStepCandidateValues, _, commitBag, _pluginRegistry, formikRef) {
    return __async(this, null, function* () {
      var _a, _b, _c, _d;
      const result = {};
      switch (this.wizardState.activeStep.stepType) {
        case "WELCOME_PAGE":
          this.memoryActiveStepCommit(activeStepCandidateValues);
          state2.disabled = false;
          break;
        case "CREATOR_USERNAME":
          try {
            yield commit(commitBag.editOrgProfile, activeStepCandidateValues);
            this.memoryActiveStepCommit(activeStepCandidateValues);
            state.disabled = false;
          } catch (error) {
            const errorMessage = (_c = (_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.error) == null ? void 0 : _c.detail.replace(/slug/gi, "username").replace(/reserved/gi, "already taken");
            (_d = formikRef.current) == null ? void 0 : _d.setErrors({
              userSlug: errorMessage
            });
            result.success = false;
          }
          break;
        case "OPTIONAL_INFO":
          yield commit2(
            commitBag.editOrgProfile,
            commitBag.updatePayoutAddress,
            activeStepCandidateValues
          );
          this.memoryActiveStepCommit(activeStepCandidateValues);
          state3.disabled = false;
          break;
        case "COMPLETE":
          this.memoryActiveStepCommit(activeStepCandidateValues);
          state4.disabled = false;
          break;
      }
      return result;
    });
  }
  canCloseAfterCommit() {
    return false;
  }
};
var manager = new ProfileSetupWizardDialogManager(
  state5,
  stepTypeStateMap
);

// src/components/profile-setup/step-content/WelcomePage.tsx
import { Typography } from "@mui/material";
import { jsx } from "react/jsx-runtime";
var WelcomePageContent = () => {
  return /* @__PURE__ */ jsx(Typography, { children: "Next, choose your unique creator ID and create a profile to showcase your identity on Phosphor, connecting you with your fans and followers." });
};

// src/components/profile-setup/step-content/Username.tsx
import { Link, Stack, TextField as TextField2, Typography as Typography2 } from "@mui/material";
import { useFormikContext } from "formik";

// src/components/CreatorRoleAutocomplete.tsx
import { Autocomplete, Box, Checkbox, Chip, FormControlLabel, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import CheckBoxBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { startCase } from "lodash";
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
var CreatorRoleAutocomplete = ({
  initialValues,
  onChange,
  size = "medium"
}) => {
  const [activeValues, setActiveValues] = useState(
    initialValues != null ? initialValues : []
  );
  const options = useMemo(() => {
    return Object.values(EditOrganizationProfileInputCreatorRolesEnum).filter((role) => role !== EditOrganizationProfileInputCreatorRolesEnum.NoneApply).map((role) => {
      return { value: role, label: startCase(role.toLowerCase().replace("_", " ")) };
    });
  }, []);
  const activeOptions = useMemo(() => {
    if (!(options == null ? void 0 : options.length) || !(activeValues == null ? void 0 : activeValues.length))
      return [];
    return activeValues.map((value) => {
      return options.find((option) => option.value === value);
    }).filter(Boolean);
  }, [activeValues, options]);
  const noneApply = useMemo(() => {
    return Boolean(
      activeValues == null ? void 0 : activeValues.find((role) => role === EditOrganizationProfileInputCreatorRolesEnum.NoneApply)
    );
  }, [activeValues]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", columnGap: 1, alignItems: "flex-end" }, children: [
    /* @__PURE__ */ jsx2(
      Autocomplete,
      {
        multiple: true,
        size,
        id: "creator-role-autocomplete",
        options,
        disableCloseOnSelect: true,
        "data-testid": "creator-role-autocomplete",
        getOptionLabel: (option) => option.label,
        renderOption: (props, option, { selected }) => /* @__PURE__ */ jsxs("li", __spreadProps(__spreadValues({}, props), { children: [
          /* @__PURE__ */ jsx2(
            Checkbox,
            {
              icon: /* @__PURE__ */ jsx2(CheckBoxBlankIcon, { fontSize: "small" }),
              checkedIcon: /* @__PURE__ */ jsx2(CheckBoxIcon, { fontSize: "small" }),
              style: { marginRight: 8 },
              checked: selected
            }
          ),
          option.label
        ] })),
        renderTags: (value, getTagProps) => value.map((option, index) => /* @__PURE__ */ jsx2(
          Chip,
          __spreadValues({
            color: "secondary",
            variant: "filled",
            label: `${option.label}`
          }, getTagProps({ index }))
        )),
        onChange: (_, chosenOpts) => {
          const newOptValues = chosenOpts.map(
            (option) => option.value
          );
          setActiveValues(newOptValues);
          onChange(newOptValues);
        },
        value: activeOptions,
        sx: {
          ".MuiInputBase-root": { height: "auto", pb: 1 },
          flexGrow: 1
        },
        renderInput: (params) => /* @__PURE__ */ jsx2(TextField, __spreadProps(__spreadValues({}, params), { label: "Self-Identification" }))
      }
    ),
    /* @__PURE__ */ jsx2(
      FormControlLabel,
      {
        control: /* @__PURE__ */ jsx2(
          Checkbox,
          {
            "data-testid": "creator-role-none-apply",
            checked: noneApply,
            onChange: (_, checked) => {
              const newValues = checked ? [EditOrganizationProfileInputCreatorRolesEnum.NoneApply] : [];
              setActiveValues(newValues);
              onChange(newValues);
            }
          }
        ),
        label: "None Apply",
        sx: { flexBasis: "175px" }
      }
    )
  ] });
};

// src/components/profile-setup/step-content/Username.tsx
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var UsernameContent = () => {
  const formik = useFormikContext();
  return /* @__PURE__ */ jsxs2(Stack, { spacing: 1, children: [
    /* @__PURE__ */ jsx3(
      TextField2,
      {
        fullWidth: true,
        id: "userSlug",
        name: "userSlug",
        type: "text",
        label: "Choose your username",
        autoFocus: true,
        "data-testid": "profile-setup-slug",
        value: formik.values["userSlug"] || "",
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        error: formik.touched["userSlug"] && Boolean(formik.errors["userSlug"]),
        disabled: formik.isSubmitting,
        helperText: formik.touched["userSlug"] && formik.errors["userSlug"]
      }
    ),
    /* @__PURE__ */ jsx3(
      CreatorRoleAutocomplete,
      {
        initialValues: formik.values.creatorRoles,
        size: "small",
        onChange: (values) => {
          formik.setFieldValue("creatorRoles", values != null ? values : []);
        }
      }
    ),
    /* @__PURE__ */ jsxs2(
      Typography2,
      {
        variant: "body2",
        sx: { marginTop: "24px !important", fontStyle: "italic", fontSize: "0.75rem" },
        children: [
          "By creating your profile, you confirm you have read and agree to Consensys\u2019",
          " ",
          /* @__PURE__ */ jsx3(
            Link,
            {
              href: "https://consensys.io/terms-of-use/",
              target: "_blank",
              rel: "noopener noreferrer",
              style: { textDecoration: "none" },
              children: "Terms of Use"
            }
          ),
          " ",
          "and",
          " ",
          /* @__PURE__ */ jsx3(
            Link,
            {
              href: "https://consensys.io/privacy-policy/",
              target: "_blank",
              rel: "noopener noreferrer",
              style: {
                textDecoration: "none"
              },
              children: "Privacy Policy"
            }
          ),
          ". We collect your name, email address and social media handle (if provided by you) in order to allow you access to your account, to manage your account, to contact you about changes to your account and, if you opt in, to send you marketing communications about Phosphor Studio. We also collect data relating to your activity on this website for product improvement purposes. We may share your information with third party providers for the purposes of payment processing, user authentication, and email communication. For more information in relation to our privacy practices, please see our",
          " ",
          /* @__PURE__ */ jsx3(
            Link,
            {
              href: "https://consensys.io/privacy-policy/",
              target: "_blank",
              rel: "noopener noreferrer",
              style: { textDecoration: "none" },
              children: "Privacy Policy"
            }
          ),
          "."
        ]
      }
    )
  ] });
};

// src/components/profile-setup/step-content/Optional.tsx
import { Box as Box2, Stack as Stack2, TextField as TextField3, Typography as Typography3 } from "@mui/material";
import { useFormikContext as useFormikContext2 } from "formik";
import {
  CommonInputLabelStack,
  FORM_PREVENT_CANCEL,
  FORM_PREVENT_SUBMIT_LOADING,
  SingleFileServerUpload,
  useAuth
} from "common-ui";
import { COMMON_IMAGE_MIME_TYPES } from "common-ui";
import { useApi } from "common-api";
import { useSnapshot } from "valtio";
import { useEffect } from "react";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var OptionalContent = () => {
  var _a;
  const { axios } = useApi();
  const { platform } = useAuth();
  const { activeStep } = useSnapshot(manager.wizardState);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/upload" : "";
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    setFieldTouched,
    setFieldValue
  } = useFormikContext2();
  useEffect(() => {
    var _a2, _b;
    if ((_a2 = platform == null ? void 0 : platform.org) == null ? void 0 : _a2.id) {
      setFieldValue("organizationId", (_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id, false);
    }
  }, [(_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id]);
  return /* @__PURE__ */ jsxs3(Stack2, { spacing: 1, children: [
    /* @__PURE__ */ jsxs3(Box2, { children: [
      /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Profile picture and banner", isOptional: true, forCtrl: "custom" }),
      /* @__PURE__ */ jsxs3(
        Stack2,
        {
          sx: {
            position: "relative",
            width: "100%",
            marginBottom: "4em !important",
            marginTop: "5px !important"
          },
          children: [
            /* @__PURE__ */ jsx4(Box2, { sx: { borderRadius: "5px", opacity: "95%" }, children: /* @__PURE__ */ jsx4(
              SingleFileServerUpload,
              {
                testId: "profile-banner-upload",
                height: "150px",
                width: "100%",
                maxFileSize: "2MB",
                disabled: isSubmitting || (activeStep == null ? void 0 : activeStep.readOnly),
                acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                titleLabel: "Upload a profile banner",
                subtitleLabel: "",
                captionLabel: ``,
                uploadUri: fileUploadUri,
                uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                onFileUploadedUrlChange: (organizationBannerUrl) => {
                  setFieldValue("bannerUrl", organizationBannerUrl, true);
                  setFieldTouched("bannerUrl", true, false);
                },
                onUploadingChange: (isUploading) => {
                  setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                  setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                }
              }
            ) }),
            /* @__PURE__ */ jsx4(
              Box2,
              {
                sx: {
                  position: "absolute",
                  top: "35%",
                  left: "3%",
                  backgroundColor: (theme) => theme.palette.shades.white,
                  borderRadius: "5px"
                },
                children: /* @__PURE__ */ jsx4(
                  SingleFileServerUpload,
                  {
                    testId: "profile-avatar-upload",
                    height: "150px",
                    width: "150px",
                    maxFileSize: "2MB",
                    disabled: isSubmitting || (activeStep == null ? void 0 : activeStep.readOnly),
                    acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                    titleLabel: "Upload avatar",
                    subtitleLabel: "",
                    captionLabel: ``,
                    uploadUri: fileUploadUri,
                    uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                    onFileUploadedUrlChange: (organizationBannerUrl) => {
                      setFieldValue("avatarUrl", organizationBannerUrl, true);
                      setFieldTouched("avatarUrl", true, false);
                    },
                    onUploadingChange: (isUploading) => {
                      setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                      setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                    }
                  }
                )
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        id: "displayName",
        name: "displayName",
        type: "text",
        "data-testid": "profile-setup-display-name",
        label: /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Display Name", isOptional: true }),
        autoFocus: true,
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        value: values["displayName"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["displayName"] && Boolean(errors["displayName"]),
        disabled: isSubmitting,
        helperText: touched["displayName"] && errors["displayName"]
      }
    ),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        id: "creatorBio",
        name: "creatorBio",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-bio",
        label: /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Bio (64 character limit)", isOptional: true }),
        value: values["creatorBio"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["creatorBio"] && Boolean(errors["creatorBio"]),
        disabled: isSubmitting,
        helperText: touched["creatorBio"] && errors["creatorBio"]
      }
    ),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        id: "websiteLink",
        name: "websiteLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-website-link",
        label: /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Website link", isOptional: true }),
        value: values["websiteLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["websiteLink"] && Boolean(errors["websiteLink"]),
        disabled: isSubmitting,
        helperText: touched["websiteLink"] && errors["websiteLink"]
      }
    ),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        name: "payoutAddress",
        label: /* @__PURE__ */ jsx4(
          CommonInputLabelStack,
          {
            label: "Payout Address",
            helpDescription: "This wallet address will receive the funds from collectible sales.",
            inputReadOnly: activeStep == null ? void 0 : activeStep.readOnly
          }
        ),
        "data-testid": "profile-setup-payout-address",
        type: "text",
        autoFocus: true,
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        value: values.payoutAddress,
        onChange: handleChange,
        onBlur: () => {
          handleBlur("payoutAddress");
        },
        error: touched.payoutAddress && Boolean(errors.payoutAddress),
        disabled: isSubmitting,
        helperText: touched.payoutAddress && errors.payoutAddress ? errors.payoutAddress : ""
      }
    ),
    /* @__PURE__ */ jsx4(Typography3, { variant: "body1", sx: { my: 1 }, children: "Social Links" }),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        id: "instagramLink",
        name: "instagramLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-instagram-link",
        label: /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Instagram", isOptional: true }),
        value: values["instagramLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["instagramLink"] && Boolean(errors["instagramLink"]),
        disabled: isSubmitting,
        helperText: touched["instagramLink"] && errors["instagramLink"]
      }
    ),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        id: "twitterLink",
        name: "twitterLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-twitter-link",
        label: /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Twitter", isOptional: true }),
        value: values["twitterLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["twitterLink"] && Boolean(errors["twitterLink"]),
        disabled: isSubmitting,
        helperText: touched["twitterLink"] && errors["twitterLink"]
      }
    ),
    /* @__PURE__ */ jsx4(
      TextField3,
      {
        fullWidth: true,
        id: "discordLink",
        name: "discordLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-discord-link",
        label: /* @__PURE__ */ jsx4(CommonInputLabelStack, { label: "Discord", isOptional: true }),
        value: values["discordLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["discordLink"] && Boolean(errors["discordLink"]),
        disabled: isSubmitting,
        helperText: touched["discordLink"] && errors["discordLink"]
      }
    )
  ] });
};

// src/components/profile-setup/step-content/Complete.tsx
import { Stack as Stack3, Typography as Typography4 } from "@mui/material";
import { jsx as jsx5, jsxs as jsxs4 } from "react/jsx-runtime";
var CompleteContent = () => {
  return /* @__PURE__ */ jsxs4(Stack3, { spacing: 1, children: [
    /* @__PURE__ */ jsx5(Typography4, { children: "Your account setup is complete and you are ready to begin sharing your creations with the world." }),
    /* @__PURE__ */ jsx5(Typography4, { children: "Dive into Phosphor and explore its array of features to launch your first drop and engage with your audience." }),
    /* @__PURE__ */ jsx5(Typography4, { children: "Remember, you can always access your profile from the top right corner of the page." })
  ] });
};

// src/events.ts
var SettingsProfileOnboardStartEvent = class {
  constructor() {
    this.id = "settings.profile-onboard.start";
  }
};
var SettingsProfileOnboardEndEvent = class {
  constructor() {
    this.id = "settings.profile-onboard.end";
  }
};

// src/components/profile-setup/CreatorProfileSetupDialog.tsx
import { Fragment, jsx as jsx6, jsxs as jsxs5 } from "react/jsx-runtime";
function CreatorProfileSetupDialog() {
  const isMobile = useMobileEnabled();
  return isMobile ? /* @__PURE__ */ jsx6(Fragment, {}) : /* @__PURE__ */ jsx6(CreatorProfileSetupDialogInner, {});
}
function CreatorProfileSetupDialogInner() {
  var _a, _b, _c;
  const { platform } = useAuth2();
  const { axios } = useApi2();
  const pluginRegistry = usePluginRegistry();
  const { activeStep, open } = useSnapshot2(manager.wizardState);
  const editOrgProfile = useEditOrgProfile((_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id);
  const { mutateAsync: updatePayoutAddress } = useUpdatePayoutAddress();
  const {
    isError: orgProfileError,
    isPending: orgProfilePending,
    data: organizationProfile,
    isFetched: orgProfileFetched
  } = useOrgProfile((_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id);
  useEffect2(() => {
    if ((organizationProfile == null ? void 0 : organizationProfile.slug) && activeStep.stepType === "WELCOME_PAGE") {
      manager.wizardState.open = false;
    }
  }, [organizationProfile, activeStep]);
  useEffect2(() => {
    if (open && (activeStep == null ? void 0 : activeStep.stepType) === "WELCOME_PAGE" && !(organizationProfile == null ? void 0 : organizationProfile.slug) && !orgProfilePending && orgProfileFetched) {
      pluginRegistry.events.publish(new SettingsProfileOnboardStartEvent());
    }
  }, [
    activeStep == null ? void 0 : activeStep.stepType,
    open,
    organizationProfile,
    orgProfilePending,
    orgProfileFetched,
    pluginRegistry.events
  ]);
  useEffect2(() => {
    if ((activeStep == null ? void 0 : activeStep.stepType) === "COMPLETE" && (organizationProfile == null ? void 0 : organizationProfile.slug)) {
      pluginRegistry.events.publish(new SettingsProfileOnboardEndEvent());
    }
  }, [activeStep == null ? void 0 : activeStep.stepType, pluginRegistry.events, organizationProfile == null ? void 0 : organizationProfile.slug]);
  const handleClose = useCallback(() => __async(this, null, function* () {
    if (activeStep.stepType === "WELCOME_PAGE" || activeStep.stepType === "CREATOR_USERNAME") {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: "Please complete the profile setup to continue",
          options: {
            type: "info"
          }
        })
      );
      manager.wizardState.open = true;
    }
  }), [activeStep.stepType, pluginRegistry]);
  const nextStepActionButtonLabel = useMemo2(() => {
    if (activeStep && activeStep.stepType === "WELCOME_PAGE") {
      return "Continue";
    } else if (activeStep && activeStep.stepType === "OPTIONAL_INFO") {
      return "Complete";
    } else if (activeStep && activeStep.stepType === "COMPLETE") {
      return "Explore Phosphor";
    } else
      return "Next";
  }, [activeStep, activeStep == null ? void 0 : activeStep.stepType]);
  const getStepContent = (stepType) => {
    return /* @__PURE__ */ jsxs5(Fragment, { children: [
      stepType === "WELCOME_PAGE" && /* @__PURE__ */ jsx6(WelcomePageContent, {}),
      stepType === "CREATOR_USERNAME" && /* @__PURE__ */ jsx6(UsernameContent, {}),
      stepType === "OPTIONAL_INFO" && /* @__PURE__ */ jsx6(OptionalContent, {}),
      stepType === "COMPLETE" && /* @__PURE__ */ jsx6(CompleteContent, {})
    ] });
  };
  const getStepSubTitle = (stepType) => {
    return /* @__PURE__ */ jsxs5(Fragment, { children: [
      stepType === "WELCOME_PAGE" && /* @__PURE__ */ jsx6(Typography5, { variant: "body1", children: "Thanks for joining us!" }),
      stepType === "CREATOR_USERNAME" && /* @__PURE__ */ jsx6(Typography5, { variant: "body1", children: "Choose and claim your Creator ID - the unique username that will represent your presence on Phosphor" }),
      stepType === "OPTIONAL_INFO" && /* @__PURE__ */ jsxs5(Stack4, { spacing: 1, children: [
        /* @__PURE__ */ jsx6(Typography5, { variant: "body1", children: "Add a personal touch to your Phosphor profile by sharing a few extra details about yourself." }),
        /* @__PURE__ */ jsx6(Typography5, { variant: "body1", children: "This is where your fans, both new and loyal, will connect with you for exclusive Airdrops, updates, and more. Make it a space that truly represents you!" })
      ] }),
      stepType === "COMPLETE" && /* @__PURE__ */ jsx6(Typography5, { variant: "body1", children: "Congratulations!" })
    ] });
  };
  if (activeStep.stepType === "WELCOME_PAGE" && (organizationProfile == null ? void 0 : organizationProfile.slug) || orgProfilePending || orgProfileError || !((_c = platform == null ? void 0 : platform.org) == null ? void 0 : _c.id)) {
    return null;
  } else {
    return /* @__PURE__ */ jsx6(
      WizardSingleDialog,
      {
        title: /* @__PURE__ */ jsxs5(Stack4, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
          /* @__PURE__ */ jsx6(Typography5, { variant: "h4", children: "Set up your account" }),
          getStepSubTitle(activeStep.stepType)
        ] }),
        minWidth: 650,
        commitBag: { editOrgProfile, updatePayoutAddress, axios },
        renderStepContent: getStepContent,
        stepActionOptions: {
          nextButtonLabel: nextStepActionButtonLabel,
          commandId: ["COMPLETE"].includes(activeStep.stepType) ? "portal.profile-edit.open" : "",
          backButtonLabel: ["COMPLETE"].includes(activeStep.stepType) ? "View your Profile" : "Back",
          showBackButton: !["WELCOME_PAGE"].includes(activeStep.stepType),
          backButtonSx: {
            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0"
            },
            color: "black ",
            border: "1px solid black "
          },
          nextButtonSx: { color: "black " }
        },
        manager,
        onClose: handleClose
      }
    );
  }
}

// src/plugins/profile-setup/Plugin.tsx
import { jsx as jsx7 } from "react/jsx-runtime";
var ProfileSetupPlugin = class extends Plugin {
  get info() {
    return {
      name: "profile-setup",
      title: "Profile Setup",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot("shell.content", () => /* @__PURE__ */ jsx7(CreatorProfileSetupDialog, {}));
    });
  }
};

// src/plugins/profile-edit/Plugin.tsx
import { Plugin as Plugin2 } from "plugin-system";
import { PORTAL_APP_BAR_USER_PROFILE_SLOT } from "common-ui";

// src/components/profile-edit/CreatorProfileEdit.tsx
import { useEffect as useEffect3, useState as useState2 } from "react";
import { useApi as useApi3, useEditOrgProfile as useEditOrgProfile2, useOrgProfile as useOrgProfile2, useUpdatePayoutAddress as useUpdatePayoutAddress2 } from "common-api";
import { NotificationEvent as NotificationEvent2, TopBarListItemBtn, useAuth as useAuth4, useMobileEnabled as useMobileEnabled2 } from "common-ui";
import { useQueryClient } from "@tanstack/react-query";
import { subscribe } from "valtio";
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";

// src/components/profile-edit/CreatorProfileEditForm.tsx
import {
  Dialog,
  DialogContent,
  Stack as Stack5,
  Typography as Typography6,
  IconButton,
  Box as Box3,
  TextField as TextField4,
  Button,
  DialogTitle,
  DialogActions
} from "@mui/material";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CloseIcon from "@mui/icons-material/Close";
import {
  CommonInputLabelStack as CommonInputLabelStack2,
  SingleFileServerUpload as SingleFileServerUpload2,
  FORM_PREVENT_CANCEL as FORM_PREVENT_CANCEL2,
  LoadingButton,
  FORM_PREVENT_SUBMIT_LOADING as FORM_PREVENT_SUBMIT_LOADING2,
  FORM_PREVENT_SUBMIT
} from "common-ui";
import { COMMON_IMAGE_MIME_TYPES as COMMON_IMAGE_MIME_TYPES2, useAuth as useAuth3 } from "common-ui";
import { Formik } from "formik";
import { useSnapshot as useSnapshot3 } from "valtio";
import { useCreatorProfilePageUri, useOrgPayoutConfig } from "common-api";

// src/state/profile-edit/index.ts
import { proxy as proxy6 } from "valtio";
import { isAddress as isAddress2 } from "ethers";
import * as z5 from "zod";
var validationSchema5 = z5.object({
  bannerUrl: z5.string().min(1).optional(),
  avatarUrl: z5.string().min(1).optional(),
  displayName: z5.string().min(1).max(32).optional(),
  creatorBio: z5.string().min(1).max(140).optional(),
  oldWebsiteLink: z5.string().optional(),
  websiteLink: z5.string().url().optional(),
  instagramLink: z5.string().min(1).refine((value) => /^.*instagram\.com.*$/.test(value), {
    message: "Please enter a valid instagram link."
  }).optional(),
  twitterLink: z5.string().min(1).refine((value) => /^.*twitter\.com.*$/.test(value), {
    message: "Please enter a valid twitter link."
  }).optional(),
  discordLink: z5.string().min(1).refine((value) => /^.*discord\..*$/.test(value), {
    message: "Please enter a valid discord link."
  }).optional(),
  creatorRoles: z5.array(
    z5.enum([
      EditOrganizationProfileInputCreatorRolesEnum.Artist,
      EditOrganizationProfileInputCreatorRolesEnum.CommunityManager,
      EditOrganizationProfileInputCreatorRolesEnum.ContentCreator,
      EditOrganizationProfileInputCreatorRolesEnum.ContentCreator,
      EditOrganizationProfileInputCreatorRolesEnum.EventOrganizer,
      EditOrganizationProfileInputCreatorRolesEnum.NoneApply
    ])
  ).optional(),
  oldPayoutAddress: z5.string().max(42).nullish(),
  payoutAddress: z5.string().max(42).nullish().refine((val) => !val || isAddress2(val), {
    message: "Please enter a valid payout address"
  }),
  organizationId: z5.string().nullish()
}).superRefine((val, ctx) => {
  if (!val.websiteLink && val.oldWebsiteLink) {
    ctx.addIssue({
      code: z5.ZodIssueCode.custom,
      message: "Website link cannot be cleared once set.",
      path: ["websiteLink"]
    });
  }
  if (!val.payoutAddress && val.oldPayoutAddress) {
    ctx.addIssue({
      code: z5.ZodIssueCode.custom,
      message: "Payout address cannot be cleared once set.",
      path: ["payoutAddress"]
    });
  }
});
var defaultState5 = {
  open: false,
  displayName: "",
  bannerUrl: "",
  avatarUrl: "",
  creatorBio: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: ""
};
var setOrganizationProfileData = (data) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  state6.displayName = (_a = data == null ? void 0 : data.data) == null ? void 0 : _a.public_name;
  state6.bannerUrl = (_c = (_b = data == null ? void 0 : data.data) == null ? void 0 : _b.media) == null ? void 0 : _c.banner;
  state6.avatarUrl = (_e = (_d = data == null ? void 0 : data.data) == null ? void 0 : _d.media) == null ? void 0 : _e.avatar;
  state6.creatorBio = (_f = data == null ? void 0 : data.data) == null ? void 0 : _f.description;
  state6.instagramLink = (_h = (_g = data == null ? void 0 : data.data) == null ? void 0 : _g.socials) == null ? void 0 : _h.instagram;
  state6.twitterLink = (_j = (_i = data == null ? void 0 : data.data) == null ? void 0 : _i.socials) == null ? void 0 : _j.twitter;
  state6.discordLink = (_l = (_k = data == null ? void 0 : data.data) == null ? void 0 : _k.socials) == null ? void 0 : _l.discord;
};
var state6 = proxy6(defaultState5);
function openProfileEditModal() {
  state6.open = true;
}
function closeProfileEditModal() {
  state6.open = false;
}
var profile_edit_default = state6;

// src/components/profile-edit/CreatorProfileEditForm.tsx
import { Fragment as Fragment2, jsx as jsx8, jsxs as jsxs6 } from "react/jsx-runtime";
var CreatorProfileEditForm = ({
  open,
  handleClose,
  handleSubmit,
  organizationProfile,
  axios
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { platform } = useAuth3();
  const { data: payoutConfig, isPending: payoutConfigPending } = useOrgPayoutConfig(
    open ? (_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id : void 0
  );
  const { uri: creatorProfilePageUri, isPending: creatorProfilePageUriPending } = useCreatorProfilePageUri(open ? (_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id : void 0);
  const defaultState6 = useSnapshot3(profile_edit_default);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/upload" : "";
  const handleOpenNewWindow = () => {
    window.open(creatorProfilePageUri, "_blank", "noopener,noreferrer");
  };
  if (creatorProfilePageUriPending || payoutConfigPending) {
    return /* @__PURE__ */ jsx8(Fragment2, {});
  }
  return /* @__PURE__ */ jsx8(
    Formik,
    {
      enableReinitialize: true,
      initialValues: {
        bannerUrl: defaultState6.bannerUrl || ((_c = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _c.banner) || "",
        avatarUrl: defaultState6.avatarUrl || ((_d = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _d.avatar) || "",
        displayName: defaultState6.displayName || (organizationProfile == null ? void 0 : organizationProfile.public_name) || "",
        creatorBio: defaultState6.creatorBio || (organizationProfile == null ? void 0 : organizationProfile.description) || "",
        oldWebsiteLink: defaultState6.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
        websiteLink: defaultState6.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
        instagramLink: defaultState6.instagramLink || ((_e = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _e.instagram) || "",
        twitterLink: defaultState6.twitterLink || ((_f = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _f.twitter) || "",
        discordLink: defaultState6.discordLink || ((_g = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _g.discord) || "",
        creatorRoles: defaultState6.creatorRoles || (organizationProfile == null ? void 0 : organizationProfile.creator_roles) || [],
        organizationId: defaultState6.organizationId || ((_h = platform == null ? void 0 : platform.org) == null ? void 0 : _h.id) || "",
        payoutAddress: (defaultState6 == null ? void 0 : defaultState6.payoutAddress) || (payoutConfig == null ? void 0 : payoutConfig.payout_address) || "",
        oldPayoutAddress: (defaultState6 == null ? void 0 : defaultState6.oldPayoutAddress) || (payoutConfig == null ? void 0 : payoutConfig.payout_address) || "",
        preventCancel: false,
        preventSubmit: false,
        preventSubmitLoading: false
      },
      validationSchema: toFormikValidationSchema(validationSchema5),
      onSubmit: (_values, actions) => {
        actions.submitForm();
      },
      children: (props) => /* @__PURE__ */ jsx8("form", { children: /* @__PURE__ */ jsxs6(
        Dialog,
        {
          sx: {
            ".MuiDialog-paper": { minHeight: 650, minWidth: 650 }
          },
          open,
          onClose: handleClose,
          children: [
            /* @__PURE__ */ jsxs6(DialogTitle, { sx: { justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsxs6(Stack5, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
                /* @__PURE__ */ jsx8(Typography6, { variant: "h4", children: "Update Phosphor Studio Profile" }),
                /* @__PURE__ */ jsx8(Typography6, { variant: "body1", children: "Update your profile with a few details about yourself." })
              ] }),
              /* @__PURE__ */ jsx8(IconButton, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx8(CloseIcon, { fontSize: "small" }) })
            ] }),
            /* @__PURE__ */ jsx8(
              DialogContent,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column"
                },
                children: /* @__PURE__ */ jsxs6(
                  Stack5,
                  {
                    sx: {
                      flex: 2,
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 1,
                      backgroundColor: "white",
                      color: "black"
                    },
                    children: [
                      /* @__PURE__ */ jsxs6(Box3, { children: [
                        /* @__PURE__ */ jsx8(
                          CommonInputLabelStack2,
                          {
                            label: "Profile picture and banner",
                            isOptional: true,
                            forCtrl: "custom"
                          }
                        ),
                        /* @__PURE__ */ jsxs6(
                          Stack5,
                          {
                            sx: {
                              position: "relative",
                              width: "100%",
                              marginBottom: "4em !important",
                              marginTop: "5px !important"
                            },
                            children: [
                              /* @__PURE__ */ jsx8(
                                Box3,
                                {
                                  sx: {
                                    backgroundColor: (theme) => theme.palette.shades.white,
                                    borderRadius: "5px",
                                    opacity: "95%"
                                  },
                                  children: /* @__PURE__ */ jsx8(
                                    SingleFileServerUpload2,
                                    {
                                      testId: "profile-banner-upload",
                                      height: "150px",
                                      width: "100%",
                                      maxFileSize: "2MB",
                                      initialValue: props.values.bannerUrl,
                                      disabled: props.isSubmitting,
                                      acceptedFileTypes: COMMON_IMAGE_MIME_TYPES2,
                                      titleLabel: "Upload a profile banner",
                                      subtitleLabel: "",
                                      captionLabel: ``,
                                      uploadUri: fileUploadUri,
                                      uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                                      onFileUploadedUrlChange: (organizationBannerUrl) => {
                                        props.setFieldValue("bannerUrl", organizationBannerUrl, true);
                                        props.setFieldTouched("bannerUrl", true, false);
                                      },
                                      onUploadingChange: (isUploading) => {
                                        props.setFieldValue(FORM_PREVENT_SUBMIT_LOADING2, isUploading, false);
                                        props.setFieldValue(FORM_PREVENT_CANCEL2, isUploading, false);
                                      }
                                    }
                                  )
                                }
                              ),
                              /* @__PURE__ */ jsx8(
                                Box3,
                                {
                                  sx: {
                                    position: "absolute",
                                    top: "35%",
                                    left: "3%",
                                    backgroundColor: (theme) => theme.palette.shades.white,
                                    borderRadius: "5px"
                                  },
                                  children: /* @__PURE__ */ jsx8(
                                    SingleFileServerUpload2,
                                    {
                                      testId: "profile-avatar-upload",
                                      height: "150px",
                                      width: "150px",
                                      maxFileSize: "2MB",
                                      initialValue: props.values.avatarUrl,
                                      disabled: props.isSubmitting,
                                      acceptedFileTypes: COMMON_IMAGE_MIME_TYPES2,
                                      titleLabel: "Upload avatar",
                                      subtitleLabel: "",
                                      captionLabel: ``,
                                      uploadUri: fileUploadUri,
                                      uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                                      onFileUploadedUrlChange: (organizationBannerUrl) => {
                                        props.setFieldValue("avatarUrl", organizationBannerUrl, true);
                                        props.setFieldTouched("avatarUrl", true, false);
                                      },
                                      onUploadingChange: (isUploading) => {
                                        props.setFieldValue(FORM_PREVENT_SUBMIT_LOADING2, isUploading, false);
                                        props.setFieldValue(FORM_PREVENT_CANCEL2, isUploading, false);
                                      }
                                    }
                                  )
                                }
                              )
                            ]
                          }
                        )
                      ] }),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "displayName",
                          name: "displayName",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-display-name",
                          label: /* @__PURE__ */ jsx8(CommonInputLabelStack2, { label: "Display Name", isOptional: true }),
                          autoFocus: true,
                          value: props.values.displayName,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["displayName"] && Boolean(props.errors["displayName"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["displayName"] && props.errors["displayName"]
                        }
                      ),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "creatorBio",
                          name: "creatorBio",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-bio",
                          label: /* @__PURE__ */ jsx8(CommonInputLabelStack2, { label: "Bio", isOptional: true }),
                          value: props.values.creatorBio,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["creatorBio"] && Boolean(props.errors["creatorBio"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["creatorBio"] && props.errors["creatorBio"]
                        }
                      ),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "websiteLink",
                          name: "websiteLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-website-link",
                          label: /* @__PURE__ */ jsx8(CommonInputLabelStack2, { label: "Website link", isOptional: true }),
                          value: props.values["websiteLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["websiteLink"] && Boolean(props.errors["websiteLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["websiteLink"] && props.errors["websiteLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          name: "payoutAddress",
                          label: /* @__PURE__ */ jsx8(
                            CommonInputLabelStack2,
                            {
                              label: "Payout Address",
                              helpDescription: "This wallet address will receive the funds from collectible sales.",
                              inputReadOnly: props.isSubmitting
                            }
                          ),
                          type: "text",
                          autoFocus: true,
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-payout-address",
                          value: props.values.payoutAddress,
                          onChange: props.handleChange,
                          onBlur: () => {
                            props.handleBlur("payoutAddress");
                          },
                          error: Boolean(props.errors.payoutAddress),
                          disabled: props.isSubmitting,
                          helperText: props.errors.payoutAddress ? props.errors.payoutAddress : ""
                        }
                      ),
                      /* @__PURE__ */ jsx8(Typography6, { variant: "body1", sx: { my: 1 }, children: "Social Links" }),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "instagramLink",
                          name: "instagramLink",
                          type: "text",
                          "data-testid": "profile-edit-instagram-link",
                          label: /* @__PURE__ */ jsx8(CommonInputLabelStack2, { label: "Instagram", isOptional: true }),
                          value: props.values["instagramLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          error: props.touched["instagramLink"] && Boolean(props.errors["instagramLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["instagramLink"] && props.errors["instagramLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "twitterLink",
                          name: "twitterLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-twitter-link",
                          label: /* @__PURE__ */ jsx8(CommonInputLabelStack2, { label: "Twitter", isOptional: true }),
                          value: props.values["twitterLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["twitterLink"] && Boolean(props.errors["twitterLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["twitterLink"] && props.errors["twitterLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx8(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "discordLink",
                          name: "discordLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-discord-link",
                          label: /* @__PURE__ */ jsx8(CommonInputLabelStack2, { label: "Discord", isOptional: true }),
                          value: props.values["discordLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["discordLink"] && Boolean(props.errors["discordLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["discordLink"] && props.errors["discordLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx8(
                        CreatorRoleAutocomplete,
                        {
                          initialValues: props.values.creatorRoles,
                          onChange: (values) => {
                            props.setFieldValue("creatorRoles", values != null ? values : []);
                          }
                        }
                      )
                    ]
                  }
                )
              }
            ),
            /* @__PURE__ */ jsxs6(DialogActions, { children: [
              /* @__PURE__ */ jsx8(
                Button,
                {
                  "data-testid": "back",
                  variant: "outlined",
                  sx: {
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0"
                    },
                    color: "black ",
                    border: "1px solid black "
                  },
                  onClick: handleOpenNewWindow,
                  children: "View Profile"
                }
              ),
              /* @__PURE__ */ jsx8(
                LoadingButton,
                {
                  "data-testid": "next",
                  variant: "contained",
                  type: "submit",
                  sx: {
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0"
                    },
                    color: "black"
                  },
                  loading: props.isSubmitting || props.values[FORM_PREVENT_SUBMIT_LOADING2],
                  title: props.values[FORM_PREVENT_SUBMIT_LOADING2] ? "This button is disabled because there is an action taking place on the form that has yet to complete." : void 0,
                  loadingText: props.isSubmitting ? "Saving..." : "Waiting...",
                  disabled: props.isSubmitting || props.values[FORM_PREVENT_SUBMIT] === true || props.values[FORM_PREVENT_SUBMIT_LOADING2] === true || !props.isValid,
                  onClick: () => handleSubmit(props.values),
                  children: "Update"
                }
              )
            ] })
          ]
        }
      ) })
    }
  );
};

// src/components/profile-edit/CreatorProfileEdit.tsx
import { Fragment as Fragment3, jsx as jsx9, jsxs as jsxs7 } from "react/jsx-runtime";
function CreatorProfileEdit() {
  const isMobile = useMobileEnabled2();
  const pluginRegistry = usePluginRegistry2();
  const { platform } = useAuth4();
  const {
    mutateAsync: updatePayoutAddress,
    isError: updatePayoutAddressError,
    reset: resetUpdatePayoutAddress
  } = useUpdatePayoutAddress2();
  const [open, setOpen] = useState2(false);
  const queryClient = useQueryClient();
  const { axios } = useApi3();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: saveOrgProfile,
    isSuccess: savedOrgProfile,
    reset: resetSaveOrgProfile,
    isError: saveOrgProfileError
  } = useEditOrgProfile2(organizationId);
  const { data: organizationProfile, isPending } = useOrgProfile2(organizationId);
  useEffect3(() => {
    if (!savedOrgProfile)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent2({
        message: "Profile updated successfully",
        options: {
          type: "success",
          toastId: "profile-update-success"
        }
      })
    );
    if (resetSaveOrgProfile)
      resetSaveOrgProfile();
  }, [savedOrgProfile, pluginRegistry, resetSaveOrgProfile]);
  useEffect3(() => {
    if (!saveOrgProfileError && !updatePayoutAddressError)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent2({
        message: "Profile update failed, please try again. If this problem persists, please contact support.",
        contactSupport: true,
        options: {
          type: "error",
          toastId: "profile-update-failed"
        }
      })
    );
    if (resetSaveOrgProfile && saveOrgProfileError)
      resetSaveOrgProfile();
    if (resetUpdatePayoutAddress && updatePayoutAddressError)
      resetUpdatePayoutAddress();
  }, [
    saveOrgProfileError,
    updatePayoutAddressError,
    pluginRegistry,
    resetSaveOrgProfile,
    resetUpdatePayoutAddress
  ]);
  const handleOpen = () => {
    queryClient.invalidateQueries({ queryKey: ["ORGANIZATION_PROFILE", organizationId] });
    openProfileEditModal();
  };
  const handleClose = () => {
    closeProfileEditModal();
  };
  useEffect3(() => {
    subscribe(profile_edit_default, () => {
      if (profile_edit_default.open) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    });
  }, []);
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a;
    try {
      if (organizationId && values.payoutAddress !== values.oldPayoutAddress) {
        yield updatePayoutAddress({
          organizationId,
          payoutAddress: values.payoutAddress
        });
      }
      const saveOrgProfileVariables = {
        description: values.creatorBio,
        public_name: values.displayName,
        socials: {
          instagram: values.instagramLink,
          twitter: values.twitterLink,
          discord: values.discordLink
        },
        media: {
          banner: values.bannerUrl,
          avatar: values.avatarUrl
        },
        creator_roles: !((_a = values.creatorRoles) == null ? void 0 : _a.length) ? [OrganizationProfileOutputCreatorRolesEnum.NoneApply] : values.creatorRoles
      };
      if (values.websiteLink) {
        saveOrgProfileVariables.website = values.websiteLink;
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      handleClose();
    } catch (e) {
    }
  });
  if (isMobile)
    return /* @__PURE__ */ jsx9(Fragment3, {});
  return /* @__PURE__ */ jsxs7(Fragment3, { children: [
    /* @__PURE__ */ jsx9(TopBarListItemBtn, { handleOpen, label: "Update Profile" }),
    !isPending && /* @__PURE__ */ jsx9(
      CreatorProfileEditForm,
      {
        open,
        handleClose,
        handleSubmit,
        organizationProfile,
        axios
      }
    )
  ] });
}

// src/components/AccountDetails.tsx
import { useState as useState3 } from "react";
import { useAuth as useAuth5 } from "common-ui";
import {
  useAllPublicNetworks,
  useApi as useApi4,
  useEditOrgProfile as useEditOrgProfile3,
  useOrgPayoutConfig as useOrgPayoutConfig2,
  useOrgProfile as useOrgProfile3,
  useUpdatePayoutAddress as useUpdatePayoutAddress3
} from "common-api";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import CloseIcon2 from "@mui/icons-material/Close";
import {
  Button as Button2,
  Dialog as Dialog2,
  DialogActions as DialogActions2,
  DialogContent as DialogContent2,
  DialogTitle as DialogTitle2,
  IconButton as IconButton2,
  ListItemButton,
  Stack as Stack6,
  Tooltip,
  Typography as Typography7
} from "@mui/material";
import { Fragment as Fragment4, jsx as jsx10, jsxs as jsxs8 } from "react/jsx-runtime";
function AccountDetails() {
  const [open, setOpen] = useState3(false);
  const [editProfileOpen, setEditProfileOpen] = useState3(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditProfileOpen(false);
  };
  return /* @__PURE__ */ jsxs8(Fragment4, { children: [
    /* @__PURE__ */ jsxs8(
      ListItemButton,
      {
        onClick: handleOpen,
        sx: {
          color: "primary.main",
          display: "flex",
          flexDirection: "row"
        },
        children: [
          /* @__PURE__ */ jsx10(DeveloperBoardIcon, {}),
          /* @__PURE__ */ jsx10(Typography7, { fontWeight: "600", marginLeft: "12px", children: "Account Details" })
        ]
      }
    ),
    /* @__PURE__ */ jsx10(
      AccountDetailsDialog,
      {
        open,
        handleClose,
        setEditProfileOpen,
        editProfileOpen
      }
    )
  ] });
}
function AccountDetailsRow({ label, value }) {
  return /* @__PURE__ */ jsxs8(Fragment4, { children: [
    /* @__PURE__ */ jsx10(Typography7, { variant: "bodyL", sx: { textAlign: "right" }, children: label }),
    /* @__PURE__ */ jsx10(Typography7, { variant: "body1", children: value })
  ] });
}
function AccountDetailsDialog({
  open,
  handleClose,
  setEditProfileOpen,
  editProfileOpen
}) {
  const { data: allNetworks, isPending: networksPending } = useAllPublicNetworks();
  const { platform } = useAuth5();
  const { axios } = useApi4();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const { data: orgPayoutConfig, isPending: orgPayoutConfigLoading } = useOrgPayoutConfig2(organizationId);
  const { data: organizationProfile, isPending } = useOrgProfile3(organizationId);
  const { mutateAsync: updatePayoutAddress } = useUpdatePayoutAddress3();
  const { mutateAsync: saveOrgProfile } = useEditOrgProfile3(organizationId);
  const userEmail = platform == null ? void 0 : platform.user.email;
  const userId = platform == null ? void 0 : platform.user.id;
  const userNetworks = platform == null ? void 0 : platform.org.network_ids;
  const walletAddress = orgPayoutConfig == null ? void 0 : orgPayoutConfig.payout_address;
  const networkDisplayNames = userNetworks == null ? void 0 : userNetworks.map((id) => {
    var _a;
    return ((_a = allNetworks.find((network) => network.id === id)) == null ? void 0 : _a.name) || `Unknown Network (${id})`;
  }).join(", ");
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a;
    try {
      if (organizationId && values.payoutAddress !== values.oldPayoutAddress) {
        yield updatePayoutAddress({
          organizationId,
          payoutAddress: values.payoutAddress
        });
      }
      const saveOrgProfileVariables = {
        description: values.creatorBio,
        public_name: values.displayName,
        socials: {
          instagram: values.instagramLink,
          twitter: values.twitterLink,
          discord: values.discordLink
        },
        media: {
          banner: values.bannerUrl,
          avatar: values.avatarUrl
        },
        creator_roles: !((_a = values.creatorRoles) == null ? void 0 : _a.length) ? [OrganizationProfileOutputCreatorRolesEnum.NoneApply] : values.creatorRoles
      };
      if (values.websiteLink) {
        saveOrgProfileVariables.website = values.websiteLink;
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      setEditProfileOpen(false);
    } catch (error) {
      console.error("Profile update failed", error);
    }
  });
  return /* @__PURE__ */ jsxs8(
    Dialog2,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 300, minWidth: 650 }
      },
      open,
      onClose: handleClose,
      children: [
        /* @__PURE__ */ jsxs8(DialogTitle2, { sx: { justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsxs8(Stack6, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
            /* @__PURE__ */ jsx10(Typography7, { variant: "h4", children: "Account Details" }),
            /* @__PURE__ */ jsx10(Typography7, { variant: "body1", children: "Detailed information about your account. These can be handy to know in the event of any issues." })
          ] }),
          /* @__PURE__ */ jsx10(IconButton2, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx10(CloseIcon2, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsxs8(
          DialogContent2,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              rowGap: 2,
              columnGap: 2
            },
            children: [
              /* @__PURE__ */ jsx10(AccountDetailsRow, { label: "Registered Email:", value: userEmail }),
              !orgPayoutConfigLoading && walletAddress && /* @__PURE__ */ jsxs8(Fragment4, { children: [
                /* @__PURE__ */ jsx10(Typography7, { variant: "bodyL", sx: { textAlign: "right" }, children: "Payout Address:" }),
                /* @__PURE__ */ jsx10(Tooltip, { title: "Click to update Payout Address", children: /* @__PURE__ */ jsx10(
                  Typography7,
                  {
                    variant: "body1",
                    onClick: () => setEditProfileOpen(true),
                    sx: { cursor: "pointer" },
                    children: walletAddress
                  }
                ) })
              ] }),
              !networksPending && /* @__PURE__ */ jsx10(AccountDetailsRow, { label: "Enabled Networks:", value: networkDisplayNames }),
              /* @__PURE__ */ jsx10(AccountDetailsRow, { label: "Organization ID:", value: organizationId }),
              /* @__PURE__ */ jsx10(AccountDetailsRow, { label: "User ID:", value: userId })
            ]
          }
        ),
        /* @__PURE__ */ jsx10(DialogActions2, { sx: { justifyContent: "end" }, children: /* @__PURE__ */ jsx10(
          Button2,
          {
            "data-testid": "next",
            variant: "contained",
            sx: {
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0"
              },
              color: (theme) => `${theme.palette.primary.main} !important`,
              "&:hover": {
                color: "white !important"
              }
            },
            onClick: () => setEditProfileOpen(true),
            children: "Edit Profile"
          }
        ) }),
        editProfileOpen && !isPending && /* @__PURE__ */ jsx10(
          CreatorProfileEditForm,
          {
            open: editProfileOpen,
            handleClose,
            handleSubmit,
            organizationProfile,
            axios
          }
        )
      ]
    }
  );
}

// src/plugins/profile-edit/Plugin.tsx
import { jsx as jsx11 } from "react/jsx-runtime";
var ProfileEditPlugin = class extends Plugin2 {
  get info() {
    return {
      name: "profile-edit",
      title: "Profile Edit",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx11(CreatorProfileEdit, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx11(AccountDetails, {}));
      registry.registerCommand({
        id: "portal.profile-edit.open",
        title: "Open Profile Edit",
        handler: () => {
          openProfileEditModal();
        }
      });
      registry.subscribeEvent("api.action", (event) => {
        const { resource, data, type } = event.data;
        if (resource === "ORGANIZATION_PROFILE" && type === "UPDATE") {
          setOrganizationProfileData(data);
        }
      });
    });
  }
};

// src/plugins/profile-view/Plugin.tsx
import { Plugin as Plugin3 } from "plugin-system";
import { PORTAL_TOP_BAR_SLOT } from "common-ui";

// src/components/profile-view/CreatorProfileChip.tsx
import { useOrgProfile as useOrgProfile4, useCreateCreatorProfilePageUri } from "common-api";
import { useMobileEnabled as useMobileEnabled3, useAuth as useAuth6 } from "common-ui";
import { IconButton as IconButton3, Tooltip as Tooltip2, Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { jsx as jsx12 } from "react/jsx-runtime";
var ViewCreatorProfile = () => {
  var _a;
  const { platform } = useAuth6();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const isMobile = useMobileEnabled3();
  const {
    mutateAsync: createConsumerProfileUri,
    isPending: consumerProfileUriPending,
    data: consumerProfileUriResult
  } = useCreateCreatorProfilePageUri(organizationId);
  const { data: organizationProfile } = useOrgProfile4(organizationId);
  const avatarUrl = (_a = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _a.avatar;
  if (!isMobile) {
    return /* @__PURE__ */ jsx12(
      IconButton3,
      {
        size: "large",
        sx: {
          height: "50px",
          width: "50px",
          padding: 0,
          ".MuiSvgIcon-root": { height: "100%", width: "100%" },
          ".MuiAvatar-root": {
            width: "50px",
            height: "50px",
            borderRadius: "50%"
          }
        },
        disabled: consumerProfileUriPending,
        onClick: () => {
          if (consumerProfileUriResult) {
            const uri = (consumerProfileUriResult == null ? void 0 : consumerProfileUriResult.data).absolute;
            if (uri) {
              window.open(uri, "_blank");
            }
          } else {
            createConsumerProfileUri().then((result) => {
              const uri = (result == null ? void 0 : result.data).absolute;
              if (uri) {
                window.open(uri, "_blank");
              }
            });
          }
        },
        children: /* @__PURE__ */ jsx12(
          Tooltip2,
          {
            title: consumerProfileUriPending ? "Getting profile URL..." : "View public profile",
            children: avatarUrl ? /* @__PURE__ */ jsx12(Avatar, { src: avatarUrl, sx: { height: "100%", width: "100%", borderRadius: "50%" } }) : /* @__PURE__ */ jsx12(AccountCircleIcon, {})
          }
        )
      }
    );
  }
  return null;
};

// src/plugins/profile-view/Plugin.tsx
import { jsx as jsx13 } from "react/jsx-runtime";
var ProfileViewPlugin = class extends Plugin3 {
  get info() {
    return {
      name: "profile-view",
      title: "View Profile",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot(PORTAL_TOP_BAR_SLOT.id, () => /* @__PURE__ */ jsx13(ViewCreatorProfile, {}), { order: 0 });
    });
  }
};
export {
  ProfileEditPlugin,
  ProfileSetupPlugin,
  ProfileViewPlugin
};
